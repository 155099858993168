@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  body {
    font-family: 'Poppins', sans-serif;
  }

  @media (max-width: 768px) {
    body {
        overflow-y: scroll; 
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none; 
        scrollbar-width: none;
    }

    body::-webkit-scrollbar {
        width: 0px;
        background: transparent; 
    }
}
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 70px; 
    background-color: #003366; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .hero-section {
    width: 100%;
    height: calc(100vh - 70px); 
    position: relative;
  }
  
  .hero-slide {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .hero-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    border-radius: 10px;
  }
  
  .overlay h2 {
    font-size: 3rem; 
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .overlay p {
    font-size: 1.25rem;
  }
  
  @media (max-width: 768px) {
    .navbar {
      height: 60px; 
    }
  
    .hero-section {
      height: calc(100vh - 60px); 
    }
  
    .overlay {
      padding: 0.5rem;
    }
  
    .overlay h2 {
      font-size: 2rem; 
    }
  
    .overlay p {
      font-size: 1rem; 
    }
  }
  
  